import { useState } from "react";
import { IAccount } from "../../entities/IAccount.entity";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../api/api";

export function AdminCreateAccountPage() {
    const [index, setIndex] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [year, setYear] = useState<number | undefined>(undefined);
    const [center, setCenter] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [displayResponse, setDisplayResponse] = useState<boolean>(false);
    const [response, setResponse] = useState<IAccount | undefined>(undefined);

    const navigate = useNavigate();

    function createUser() {
        setLoading(true);
        api("post", "api/account", { index: index, firstName: firstName, lastName: lastName, year: year, center: center })
            .then(res => {
                if (res.status === "ok") {
                    //navigate("/", { replace: true });
                    setLoading(false);
                    setResponse(res.data);
                    setDisplayResponse(true);
                }
                else {
                    setLoading(false);
                }
            });
    }

    const handleKeyPress = (event: any) => {
        // look for the `Enter` keyCode
        if (event.keyCode === 13 || event.which === 13) {

            if (!index.match("^[0-9]{10}$") || firstName.length <= 0 || lastName.length <= 0 || year === undefined || center === undefined) {

            }
            else {
                createUser();
            }

        }
    }





    return (
        <>
            {!loading && !displayResponse && <div className="formContainer ms-auto me-auto mt-5">
                <div className="text-center mb-3 mt-3">
                    <h1>Nova dodela</h1>
                </div>
                <div className="mb-2">
                    <label htmlFor="indexInput" className="form-label">Indeks</label>
                    <input onKeyDown={handleKeyPress} type="text" min={1} className="form-control" id="indexInput" value={index} onChange={e => setIndex(e.currentTarget.value)} />

                </div>
                {(index.length > 0 && !index.match("^[0-9]{10}$")) &&
                    <div className="alert alert-danger mt-3" role="alert">
                        Indeks nije u ispravnom formatu.<br/>
                        Primer ispravno unesenog indeksa: 2024123456
                    </div>
                }


                <div className="mb-2">
                    <label htmlFor="firstNameInput" className="form-label">Ime</label>
                    <input onKeyDown={handleKeyPress} type="text" className="form-control" id="firstNameInput" value={firstName} onChange={e => setFirstName(e.currentTarget.value)} />
                </div>
                <div className="mb-2">
                    <label htmlFor="lastNameInput" className="form-label">Prezime</label>
                    <input onKeyDown={handleKeyPress} type="text" className="form-control" id="lastNameInput" value={lastName} onChange={e => setLastName(e.currentTarget.value)} />
                </div>

                <div className="mb-2">
                    <label htmlFor="yearInput" className="form-label">Godina studija</label>
                    <select onKeyDown={handleKeyPress} className="form-select" aria-label="Default select example" id="yearInput" value={year} onChange={e => setYear(+e.currentTarget.value)}>
                        <option value={undefined} disabled selected>Izaberi godinu</option>
                        <option value="1">Prva godina</option>
                        <option value="2">Druga godina</option>
                        <option value="3">Treća godina</option>
                        <option value="4">Četvrta godina</option>
                    </select>
                </div>

                <div className="mb-3">
                    <label htmlFor="centerInput" className="form-label">Centar</label>
                    <select onKeyDown={handleKeyPress} className="form-select" aria-label="Default select example" id="centerInput" value={center} onChange={e => setCenter(e.currentTarget.value)}>
                        <option value={undefined} disabled selected>Izaberi centar</option>
                        <option value="Beograd">Beograd</option>
                        <option value="Niš">Niš</option>
                        <option value="Novi Sad">Novi Sad</option>
                        <option value="Bijeljina">Bijeljina</option>
                    </select>
                </div>

                <div className="d-flex justify-content-center">
                    <button disabled={!index.match("^[0-9]{10}$") || firstName.length <= 0 || lastName.length <= 0 || year === undefined || center === undefined} onClick={() => createUser()} type="button" className="btn btn-primary addUserBtn">Potvrdi</button>
                </div>

            </div>
            }
            {loading && <div className="text-center mt-3">
                <div className="spinner-border mySpinner" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
            {!loading && displayResponse &&
                <div className="formContainer ms-auto me-auto mt-5">
                    <div className="text-center mb-3 mt-3">
                        <img className="loginLogo" src={process.env.PUBLIC_URL + '/singi-blue.svg'} alt="MTutor 2.0" />
                    </div>
                    <div className="fs-4">Indeks: <strong>{response?.index}</strong></div>
                    <div className="fs-4">Ime: <strong>{response?.firstName}</strong></div>
                    <div className="fs-4">Prezime: <strong>{response?.lastName}</strong></div>
                    <div className="fs-4">Godina: <strong>{response?.year}</strong></div>
                    <div className="fs-4 mb-3">Centar: <strong>{response?.center}</strong></div>
                    <div className="fs-4">SAP nalog: <strong>LEARN-{response?.sapAccount.toString().padStart(3, "0")}</strong></div>
                    <div className="fs-4 mb-3">Inicijalna lozinka je: <strong>tlestart</strong></div>
                    <Link to="/admin/account-list" className="linkZaSap">Nazad na listu naloga</Link>
                </div>
            }
        </>
    );
}