import { useState } from "react";
import { api } from "../../api/api";
import AuthStore from "../../api/AuthStore";
import { Link, Navigate, useNavigate } from "react-router-dom";

export function LoginPage() {

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showLoginAlert, setShowLoginAlert] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const doLogin = () => {
        setLoading(true);
        api("post", "/auth/login", { username: username, password: password })
            .then(res => {
                if (res.status !== "ok") {
                    setLoading(false);
                    throw new Error("Login unsuccessful. Caused by: " + JSON.stringify(res.data));
                }
                return res.data;
            })
            .then(data => {
                AuthStore.dispatch({ type: "update", key: "accessToken", value: data?.accessToken });
                AuthStore.dispatch({ type: "update", key: "refreshToken", value: data?.refreshToken });
                AuthStore.dispatch({ type: "update", key: "id", value: +(data?.id) });
                AuthStore.dispatch({ type: "update", key: "identity", value: username });
                AuthStore.dispatch({ type: "update", key: "role", value: "admin" });

                setLoading(false);
                navigate("/admin/account-list", { replace: true });
            })
            .catch(error => {
                setShowLoginAlert(true);
                setPassword("");
                setTimeout(() => setShowLoginAlert(false), 3000);
            });

    }

    const handleKeyPress = (event: any) => {
        // look for the `Enter` keyCode
        if (event.keyCode === 13 || event.which === 13) {
            doLogin();
        }
    }

    return (
        <div className="container forma">
            <div className="forma mt-5">
                <div className="text-center mb-3 mt-3">
                    <img className="loginLogo" src={process.env.PUBLIC_URL + '/singi-blue.svg'} alt="MTutor 2.0" />
                </div>
                <div className="mb-3 mt-3">
                    <img className="loginLogo sapLogo" src={process.env.PUBLIC_URL + '/SAP_2011_logo.svg.png'} alt="SAP" />
                </div>

                <div className="form-outline mb-4">
                    <input type="text" id="usernameInput" className="form-control" value={username} onChange={e => setUsername(e.currentTarget.value)} onKeyDown={handleKeyPress} />
                    <label className="form-label" htmlFor="usernameInput">Korisničko ime</label>
                </div>


                <div className="form-outline mb-4">
                    <input type="password" id="passwordInput" className="form-control" value={password} onChange={e => setPassword(e.currentTarget.value)} onKeyDown={handleKeyPress} />
                    <label className="form-label" htmlFor="passwordInput">Lozinka</label>
                </div>

                <div className="text-center">
                    <button disabled={username.length < 1 || password.length < 1} type="button" className="loginBtn btn btn-primary btn-block mb-4" onClick={() => doLogin()} >Prijavi se</button>
                </div>
                {loading &&
                    <div className="text-center">
                        <div className="spinner-border mySpinner" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                }
                {showLoginAlert && <div className="alert alert-danger text-center" role="alert">
                    Pogrešno korisničko ime ili lozinka.
                </div>}
            </div>
        </div>
    );
}