import axios, { AxiosResponse } from "axios";
import AuthStore from "./AuthStore";
import Swal from "sweetalert2";

export type TApiMethod = "get" | "post" | "put" | "delete";
export type TApiResponse = "ok" | "error" | "login";
export interface IApiResponse {
    status: TApiResponse;
    data: any;
}
interface IApiArguments {
    method: TApiMethod,
    path: string,
    data: any | undefined,
    attemptToRefreshToken: boolean,
}

export function api(
    method: TApiMethod,
    path: string,
    data: any | undefined = undefined,
    attemptToRefreshToken: boolean = true,
): Promise<IApiResponse> {
    return new Promise(resolve => {
        axios({
            method: method,
            baseURL: process.env.REACT_APP_API_URL,
            url: path,
            data: data ? JSON.stringify(data) : undefined,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + AuthStore.getState().accessToken,
            },
            timeout: 5000
        })
            .then(res => handleApiResponse(res, resolve))
            .catch(err => handleApiError(err, resolve, {
                method, path, data, attemptToRefreshToken,
            }));
    });
}

function handleApiError(err: any, resolve: (value: IApiResponse | PromiseLike<IApiResponse>) => void, args: IApiArguments) {
    if (err.code === "ERR_NETWORK") {
        
        AuthStore.dispatch({ type: "reset" });
        Swal.fire({
            icon: "error",
            text: "Problem u komunikaciji sa serverom! / Server not responding!",
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Ok",
            confirmButtonColor: "#1DA1F2",
            allowOutsideClick: false,
            background: "#363636",
            color: "#FFFFFF",

        });
    }

    if (err?.response?.status === 401 || err?.response?.status === 403) {
        if (args.attemptToRefreshToken) {
            refreshToken()
                .then(token => {
                    if (!token) {
                        return resolve({
                            status: "login",
                            data: "You must be logged in!"
                        });
                    }
                    return token;
                })
                .then(token => {
                    AuthStore.dispatch({ type: "update", key: "accessToken", value: token });
                    return api(args.method, args.path, args.data, false);
                })
                .then(res => resolve(res))
                .catch(error => {
                    resolve(error);
                });
        }
        else {
            return resolve({
                status: "login",
                data: "You are not logged in!"
            });
        }
    }
    else {
        return resolve({
            status: "error",
            data: err?.response?.data
        });
    }


}

function handleApiResponse(res: AxiosResponse<any, any>, resolve: (value: IApiResponse | PromiseLike<IApiResponse>) => void) {

    if (res?.status < 200 || res?.status >= 300) {
        return resolve({
            status: 'error',
            data: res + '',
        });
    }

    resolve({
        status: 'ok',
        data: res.data,
    });
}

function refreshToken(): Promise<string | null> {

    return new Promise(resolve => {
        const roles = AuthStore.getState().role;

        if (roles === "visitor") {
            return resolve(null);

        }

        axios({
            method: "post",
            baseURL: process.env.REACT_APP_API_URL,
            url: "/auth/refresh",
            headers: {
                "Authorization": "Bearer " + AuthStore.getState().refreshToken,
            },
            data: {
                token: AuthStore.getState().refreshToken
            }
        })
            .then(res => {

                if (res.status !== 201) {
                    return resolve(null);
                }
                AuthStore.dispatch({ type: "update", key: "id", value: res.data.id });
                AuthStore.dispatch({ type: "update", key: "identity", value: res.data.username });
                AuthStore.dispatch({ type: "update", key: "refreshToken", value: res.data.refreshToken });
                resolve(res.data.accessToken);

            })
            .catch(() => {
                AuthStore.dispatch({ type: "reset" });
                window.location.reload();
                resolve("Token refresh failed!");
            })
    });

}