import { useEffect, useState } from "react";
import { IAccount } from "../../entities/IAccount.entity";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { api } from "../../api/api";

export function AccountListPage() {

    const [accounts, setAccounts] = useState<IAccount[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    function loadAccounts() {
        api("get", "api/account")
            .then(res => {
                if (res.status === "ok") {
                    setAccounts(res.data);
                    setLoading(false);
                }
            });
    }

    useEffect(loadAccounts, []);

    return (
        <>
            {!loading && <div className="overflowClass">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th className="ps-0" colSpan={6}><Link to="/admin/admin-create" className="btn btn-primary addUserBtn"><FontAwesomeIcon icon={faPlus} /> Napravi novi nalog</Link></th>
                        </tr>
                        <tr>
                            <th>Indeks</th>
                            <th>SAP nalog</th>
                            <th>Ime</th>
                            <th>Prezime</th>
                            <th>Godina</th>
                            <th>Centar</th>                         
                        </tr>
                    </thead>
                    <tbody>
                        {accounts.length > 0 && accounts.map(account => (
                            <tr key={account.accountId}>
                                <td>{account.index}</td>
                                <td>LEARN-{account.sapAccount.toString().padStart(3, "0")}</td>
                                <td>{account.firstName}</td>
                                <td>{account.lastName}</td>
                                <td>{account.year}</td>
                                <td>{account.center}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>}
            {loading && <div className="text-center">
                <div className="spinner-border mySpinner" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
        </>
    );
}