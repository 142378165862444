import Swal from "sweetalert2";
import AuthStore from "../../api/AuthStore";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";

export function Header() {

    const navigate = useNavigate();

    function doLogout() {
        AuthStore.dispatch({ type: "reset" });
        navigate("/admin", { replace: true });
    }

    function doLogoutOpenDialog() {
        Swal.fire({
            text: "Potvrdi",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Da',
            confirmButtonColor: "#1DA1F2",
            denyButtonText: `Ne`,
            cancelButtonText: `Ne`,
            allowOutsideClick: false,
            background: "#363636",
            color: "#FFFFFF",

        }).then((result) => {
            if (result.isConfirmed) {
                doLogout();
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        });
    }

    return (

        <div className="d-flex justify-content-center align-items-center">
            <Link to="/">
                <div className="d-flex justify-content-center mt-3 mb-3">
                    <div className="titleHeight me-2">
                        <img className="logoSmall" src={process.env.PUBLIC_URL + '/singi-blue-logo.svg'} alt="Singidunum Logo" />
                    </div>
                    <span className="titleSize fw-bold ms-2">SAP portal</span>
                </div>
            </Link>
            <div className="nav-link underlineBtn logoutBtn ms-auto" style={{ cursor: "pointer" }} onClick={() => doLogoutOpenDialog()}><FontAwesomeIcon icon={faSignOut} /> Odjavi se</div>
        </div>

    );
}