import React, { useState } from 'react';
import './App.css';
import AuthStore from '../../api/AuthStore';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LoginPage } from '../LoginPage/LoginPage';
import { AccountListPage } from '../AccountListPage/AccountListPage';
import { CreateAccountPage } from '../CreateAccountPage/CreateAccountPage';
import { AdminCreateAccountPage } from '../AdminCreateAccountPage/AdminCreateAccountPage';
import { Header } from '../Header/Header';


function App() {

  const [role, setRole] = useState<"visitor" | "admin">(AuthStore.getState().role);

  AuthStore.subscribe(() => {
    setRole(AuthStore.getState().role);
  });

  return (
    <Provider store={AuthStore}>
      <div className="containter">
        <BrowserRouter>
          {role !== "visitor" && <Header></Header>}
          <Routes>

            {role === "visitor" && <Route path="/" element={<CreateAccountPage />}></Route>}
            {role === "visitor" && <Route path="/create" element={<CreateAccountPage />}></Route>}
            {role === "visitor" && <Route path="/admin" element={<LoginPage />}></Route>}
            {role === "visitor" && <Route path="/admin/account-list" element={<LoginPage />}></Route>}
            {role === "visitor" && <Route path="/admin/admin-create" element={<LoginPage />}></Route>}

            {role === "admin" && <Route path="/admin/account-list" element={<AccountListPage />}></Route>}
            {role === "admin" && <Route path="/" element={<AccountListPage />}></Route>}
            {role === "admin" && <Route path="/admin/admin-create" element={<AdminCreateAccountPage />}></Route>}
          </Routes>
        </BrowserRouter>
      </div>
    </Provider>
  );
}

export default App;
